$("[data-scroll-top]").click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href'),
		top = $(alvo).offset().top,
		h = $(window).height() / 3;

	$('body').animate({
		scrollTop: top - h},
		1000, function() {
		/* stuff to do after animation is complete */
	});
});

$(document).scroll(function(event) {
	var t = $(this).scrollTop(),
		b = $(".banners"),
		alvo = $(".topo");


		if( $(".banners").length > 0 ){
			
			var bt = b.offset().top,
			bh = b.height(),
			mt = bt + bh;

			if(t > mt) {
				$(alvo).addClass('topo-fixed');
				$(alvo).find(".navbar-topo").css('top', '0px');
			}else{
				$(alvo).find(".navbar-topo").css('top', (t * -1) + "px");
				$(alvo).removeClass('topo-fixed');
			}

		}else{
			var i = $(".internas-header"),
			it = i.offset().top,
			ih = i.height(),
			mt = it + ih;

			if(t > mt) {
				$(alvo).addClass('topo-fixed');
				$(alvo).find(".navbar-topo").css('top', '0px');
			}else{
				$(alvo).find(".navbar-topo").css('top', (t * -1) + "px");
				$(alvo).removeClass('topo-fixed');
			}
		}
});